import { Icon } from '@iconify/react'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import React from 'react'
import { toast as _toast, type ToastOptions } from 'react-toastify'
import MessageComp from './messageComp'

type ToastOptionsType = ToastOptions<{}> | undefined

export const WuToastTypes = ['success', 'error', 'info', 'warning'] as const

export type WuToastProps = {
  message: string | React.ReactNode
  options?: ToastOptionsType
  actionComponent?: React.ReactNode
}

const toast = {
  ..._toast,
  success: ({ message, options, actionComponent }: WuToastProps) => {
    _toast.success(<MessageComp message={message} actionComponent={actionComponent} />, {
      ...options,
      icon: () => <CheckCircleOutlineIcon className='h-5 w-5 text-green-500' />,
    })
  },
  error: ({ message, options, actionComponent }: WuToastProps) => {
    _toast.error(<MessageComp message={message} actionComponent={actionComponent} />, {
      ...options,
      icon: <Icon icon='mdi:close-octagon-outline' height={20} width={20} color='#f06449' />,
    })
  },
  info: ({ message, options, actionComponent }: WuToastProps) => {
    _toast.info(<MessageComp message={message} actionComponent={actionComponent} />, {
      ...options,
      icon: <Icon icon='mdi:information-outline' height={20} width={20} color='#bdc0c0' />,
    })
  },
  warning: ({ message, options, actionComponent }: WuToastProps) => {
    _toast.warning(<MessageComp message={message} actionComponent={actionComponent} />, {
      ...options,
      icon: <Icon icon='mdi:alert-rhombus-outline' height={20} width={20} color='#ffde00' />,
    })
  },
}

export default toast
