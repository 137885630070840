export const EXPECTED_ERROR_CODES: { [key: string]: string } = {
  DUPLICATED_CARD: 'duplicated_card',
}

export const EXPECTED_ERROR_DETAILS: { [key: string]: string } = {
  NEED_TO_SIGN_IN_OR_SIGN_UP: 'You need to sign in or sign up before continuing.',
}

export const EXPECTED_ERROR_MESSAGES: { [key: string]: string } = {
  CHUNK_LOAD_ERROR: 'ChunkLoadError',
  REQUEST_ABORTED: 'Request aborted',
  NETWORK_ERROR: 'Network Error',
  304: '304',
  ABORTED: 'aborted',
  NOT_ALLOWED_ERROR: 'NotAllowedError',
}

export const EXPECTED_ERROR_CLASS: { [key: string]: string } = {
  AxiosError: 'AxiosError',
  CHUNK_LOAD_ERROR: 'ChunkLoadError',
  NOT_ALLOWED_ERROR: 'NotAllowedError',
  ABORTED: 'aborted',
}
